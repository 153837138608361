body {
  margin: 0;
  padding: 0;
  background: url('1.jpg');
  background-size: cover;
  backdrop-filter: blur(3rem);
  color:white;
  width: 100vw;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column-reverse;
  overflow-x: hidden;
}

#centerer{
  height: 100vh;
  width: 100vw;
}
#center{
  position: absolute;
  left: 50vw;
  top: 50vh;
  transform: translate(-50%,-50%);
  text-align: center;
}
.h1cik{
  width: 100vw !important;
  font-size:48pt !important;
  letter-spacing: 4px !important;
  font-weight: 100 !important;
  color: white;
  line-height: 70px;
  padding-bottom: 20px;
}
.pcik{
  font-size: larger !important;
  line-height: 20px !important;
}